<template>
  <div class="wrapper">
    <!-- 适老化改造 -->
    <vxe-toolbar>
      <template v-slot:buttons>
        <vxe-button status="my-orange" @click="showSearch(false)" v-if="seachStatus">隐藏查询</vxe-button>
        <vxe-button status="my-orange" icon="fa fa-search" @click="showSearch(true)" v-if="!seachStatus">查询</vxe-button>
<!--                <vxe-button status="my-orange" icon="" @click="exportElderlyOriented">导出</vxe-button>-->
      </template>
    </vxe-toolbar>
    <div class="search" v-if="seachStatus">
      <vxe-form title-align="right" title-width="120" title-colon :data="listQuery" @submit="handleSearch(true)"
                @reset="handleSearch(true, true)">
        <vxe-form-item title="选择查询日期" span="12">
          <el-date-picker
              v-model="time"
              size="small"
              clearable
              type="datetimerange"
              align="center"
              value-format="yyyy-MM-dd HH:mm:ss"
              start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期"
              style="minWidth: 100%;"
          />
        </vxe-form-item>
        <vxe-form-item title="从业人员姓名" span="6">
          <vxe-input v-model="listQuery.param.serviceWorkPersonName" placeholder="请输入从业人员姓名" clearable/>
        </vxe-form-item>
        <vxe-form-item title="联系方式" span="6">
          <vxe-input v-model="listQuery.param.serviceOldPersonName" placeholder="请输入联系方式" clearable/>
        </vxe-form-item>
        <vxe-form-item title="常住地址" span="18" class="area-form">
          <RegionForm ref="usualTenant" v-model="usualTenant"/>
        </vxe-form-item>
        <vxe-form-item align="center" span="24">
          <vxe-button type="submit" status="my-orange" icon="fa fa-check">查询</vxe-button>
          <vxe-button type="reset" status="my-orange" icon="fa fa-undo">重置</vxe-button>
        </vxe-form-item>
      </vxe-form>
    </div>
    <div class="content-list">
      <div class="content-one back-1">
        <div>总人数</div>
        <div class="data">{{ data.totalPeopleCount }}</div>
      </div>
      <div class="content-one back-2">
        <div>评估人数</div>
        <div class="data">{{ data.assessPeopleCount }}</div>
      </div>
      <div class="content-one back-3">
        <div>服务总人次</div>
        <div class="data">{{ data.servicePeopleTimes }}</div>
      </div>
      <div class="content-one back-4">
        <div>电话慰问总数</div>
        <div class="data">{{ data.callPeopleTimes }}</div>
      </div>
      <div class="content-one-list" v-for="(item,index) in data.itemList" :key="index">
        <div class="top">
          <img src="@/assets/images/elderly-icon.png" class="elderly-icon">
          <div class="top-name">{{ item.name }}</div>
        </div>
        <div class="bottom">
          {{ item.count }}
        </div>
        <div class="content-device-list">
          <div class="device-one" v-for="(deviceItem,deviceIndex) in item.children" :key="deviceIndex">
            <div>{{ deviceItem.name }}</div>
            <div>{{ deviceItem.count }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import UploadImg from '@/components/UploadImg.vue'
import axios from 'axios'
import api from '@/store/API/api'
import { default as CustomTemplate } from '@/components/CustomList/CustomTemplate'
import RegionForm from '@/components/RegionForm.vue'

export default {
  components: {
    UploadImg, ...CustomTemplate, RegionForm
  },
  data() {
    return {
      seachStatus: false,
      time: '',
      data: {
        assessPeopleCount: 0,
        callPeopleTimes: 0,
        servicePeopleTimes: 0,
        totalPeopleCount: 0,
        itemList: [],
      },
      usualTenant: [],
      listQuery: {
        param: {
          startSearchTime: '',
          endSearchTime: '',
          serviceWorkPersonName: '',
          serviceOldPersonName: '',
          regionId: ''
        }
      }
    }
  },
  created() {
    this.getData()
  },
  computed: {
    ...mapState([
      'layouts'
    ])
  },
  methods: {
    ...mapActions([
      'visitingStatistics'
    ]),
    // 导出功能
    exportElderlyOriented() {
      let url = process.env.NODE_ENV === 'development' ? '/api' : process.env.VUE_APP_BASEURL
      url += api.exportElderlyOriented
      const myObj = {
        method: 'get',
        url,
        fileName: '适老化改造',
        params: {}
      }
      this.$tools.exportMethodNoFileName(myObj)
    },
    // 是否显示查询功能
    showSearch(bool) {
      this.seachStatus = bool
    },
    handleSearch(resetCurrentPage = false, resetSearch = false) {
      if (resetSearch) {
        Object.assign(this.listQuery, this.$options.data.call(this).listQuery)
        this.time = ''
        this.usualTenant = []
      }
      this.getData()
    },
    getData() {
      if (this.time !== '') {
        this.listQuery.param.startSearchTime = this.time[0]
        this.listQuery.param.endSearchTime = this.time[1]
      } else {
        this.listQuery.param.startSearchTime = ''
        this.listQuery.param.endSearchTime = ''
      }
      if (this.usualTenant.length > 0) {
        this.listQuery.param.regionId = this.usualTenant[this.usualTenant.length - 1]
      } else {
        this.listQuery.param.regionId = ''
      }
      this.visitingStatistics(this.listQuery.param).then(res => {
        if (res.code === 200) {
          this.data = res.data
        }
      })
    }
  }

}
</script>
<style>
.el-table thead tr th:first-child div {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.wrapper {
  overflow: auto;

  .page {
    overflow: initial;
  }
}

.w-100 {
  width: 100% !important;
}

v-deep .el-form-item__label {
  text-align: left !important;
  vertical-align: middle;
  float: left;
  font-size: 14px;
  color: #606266;
  line-height: 40px;
  padding: 0 12px 0 0;
  box-sizing: border-box;
}

.table-top {
  width: 100%;
  height: 56px;
  background: #FFFFFF;
  border: 1px solid #E9EBEC;
  border-bottom: none;
}

.content-list {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.content-one {
  width: calc((100% - 120px) / 4);
  height: 200px;
  margin-right: 40px;
  margin-top: 32px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #FFFFFF;
  font-size: 20px;
}

.data {
  margin-top: 25px;
  font-size: 48px;
}

.content-one:nth-child(4n) {
  margin-right: 0 !important;
}

.back-1 {
  background: linear-gradient(to right, #C586F5, #7CD5EC)
}

.back-2 {
  background: linear-gradient(to right, #FFB07B, #FF68A4)
}

.back-3 {
  background: linear-gradient(to right, #B4E88D, #2FBFB9)
}

.back-4 {
  background: linear-gradient(to right, #E899E6, #9954FF)
}

.back-5 {
  background: linear-gradient(to right, #F1CA61, #F78B54)
}

.back-6 {
  background: linear-gradient(to right, #8EF7B5, #5DC7F6)
}

.back-7 {
  background: linear-gradient(to right, #FC93FA, #FF9D81)
}

.back-8 {
  background: linear-gradient(to right, #9B8EF2, #54D1FF)
}

.content-one-list {
  width: calc((100% - 136px) / 4);
  margin-right: 40px;
  margin-top: 32px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #FFFFFF;
  font-size: 20px;
  position: relative;
}

.content-one-list:nth-child(4n) {
  margin-right: 0 !important;
}

.top {
  width: calc(100% - 4px);
  height: 168px;
  border: 2px solid #2D8CF0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #333333;
  background: #FFFFFF;
  z-index: 2;
}

.elderly-icon {
  width: 57px;
  height: 64px;
}

.top-name {
  margin-top: 20px;
  font-size: 18px;
}

.bottom {
  width: calc(100%);
  margin-top: -10px;
  height: 82px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  color: #FFFFFF;
  border-radius: 0 0 10px 10px;
  background: #2D8CF0;
}

.content-device-list {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: calc(100% - 64px);
  height: calc(100% - 24px);
  background: #2D8CF0;
  opacity: 0.85;
  border-radius: 10px;
  overflow: auto;
  display: none;
  padding: 12px 32px;
}

.content-one-list:hover > .content-device-list, .content-device-list:hover {
  display: block !important;
}

.device-one {
  border-bottom: 1px solid #FFFFFF;
  padding: 12px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #FFFFFF;
  font-size: 18px;
}

.device-one:last-child {
  border: none;
}
</style>
